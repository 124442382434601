.textHistory{
  text-align: justify;
  color: #474747;
  z-index:2;
}
.title-about{
  color: #0db26b;
  text-transform: uppercase;
  font-weight: bold;
}
.bgLcFarma{
  width: 356px;
  height: 248px;
}
.imgColaborator{
  width: 150px;
  height: 75px;
}
.imgColaborator2{
  width: 150px;
  height: 75px;
}
.imgColaborator3{
  width: 180px;
  height: 50px;

}
.multiLineTextLeft{
  white-space: pre-line;
  text-align: left;
}
.multiLineText{
  white-space: pre-line;
}
