.slidebar {
  background-color: #377968;
}
.slideBtn {
  background-color: rgb(38, 145, 109) !important;
  text-align: center;
  padding: 0;
}
.btn-slider {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.slideBtn:hover {
  background-color: rgb(55, 173, 134) !important;
}
.slideBtn a {
  color: #ffff;
  text-decoration: none;
}
.slideBtn a:hover {
  color: #ffff;
  text-decoration: none;
}
.slide {
  background-color: rgb(48, 90, 65) !important;
}
.imageFooter {
  width: 50px;
}
