@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@300&display=swap);
.icon {
  margin-left: 5px;
  width: 25px;
}

.form-check-label {
  color: #ffff;
}

.logo {
  background: #ffff;
  border-radius: 5px;
  padding: 5px;
  min-width: 142px;
  min-height: 98px;
}

.logoText {
  justify-content: flex-end;
}

.navbar-header {
  background: #097747;
}

.navbar-max-screen {
  background: #ffffff;
  margin: 30px 0;
}

.navbar-min-screen {
  background: #097747;
  margin: 0;
}

.navbar .navbar-content {
  background: #097747;
}

.navbar-content {
  border-radius: 20px;
}

.nav-link {
  color: #ffff;
  font-size: 16px;
  font-weight: bold;
  margin: 0 10px;
}

.nav-link:hover {
  color: #BABABA !important;
}

.brand-header {
  color: #ffff;
  text-align: left;
  text-decoration: none;
}

.brand-header:hover {
  color: #ffff;
}

.navbar-title,
.navbar-subtitle {
  font-family: "Fredoka One", cursive;
  font-style: italic;
  letter-spacing: 2px;
}

.navbar-title {
  font-size: 25px;
}

.navbar-subtitle {
  font-size: 15px;
}

.navbar-info {
  font-weight: bold;
}

.title {
  background: #097747;
  color: rgb(241, 241, 241);
  padding: 30px;
  height: 100px;
  text-align: left;
}

.title h2 {
  font-weight: bold;
  margin-left: 25px;
  text-transform: uppercase;
}

h4 {
  color: rgb(92, 148, 111);
}

.imgModal {
  width: 250px;
  height: 250px;
}

.btn-navbar {
  text-align: right;
}

.icon-btn-navbar {
  border: 2px solid transparent;
  border-radius: 10px;
  padding: 1px;
}

.icon-btn-navbar:hover {
  color: rgb(201, 201, 201);
  background: #0b5a379a;
}
.item-caption1,
.item-caption2,
.item-caption3 {
  color: rgb(235, 230, 230);
  padding: 5px;
  font-weight: bold;
  margin: 0 5px;
}

.item-caption1 h5,
.item-caption2 h5,
.item-caption3 h5 {
  font-weight: bolder;
  text-transform: uppercase;
}

.item-caption1 {
  background: rgb(51, 172, 131, 0.9);
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.item-caption2 {
  background: rgb(41, 133, 102, 0.9);
}

.item-caption3 {
  background: rgb(24, 90, 68, 0.9);
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

.carousel-item {
  background: #ffff;
}

.imgCarousel {
  height: 100vh !important;
}

@media screen and (max-width: 850px) {
  .imgCarousel {
    height: 35vh !important;
  }

  .carousel-item {
    height: 680px !important;
  }

  .item-caption1,
  .item-caption2,
  .item-caption3 {
    border-radius: 20px !important;
    margin: 3px 0;
    min-width: 100vw;
  }

  .item-caption1 {
    background: rgb(51, 172, 131);
  }

  .item-caption2 {
    background: rgb(41, 133, 102);
  }

  .item-caption3 {
    background: rgb(24, 90, 68, 0.9);
  }

  .nextItem,
  .prevItem {
    visibility: hidden;
  }
}

.animation {
    background: linear-gradient(245deg, #135d30, #479779);
    background-size: 600% 600%;

    -webkit-animation: animationGradient 5s ease infinite;
    animation: animationGradient 5s ease infinite;
    color: white;
}

@-webkit-keyframes animationGradient {
    0%{background-position:0% 81%}
    50%{background-position:100% 20%}
    100%{background-position:0% 81%}
}
@keyframes animationGradient {
    0%{background-position:0% 81%}
    50%{background-position:100% 20%}
    100%{background-position:0% 81%}
}
.iconHome{
    font-size: 50px;
    margin-top: 25px;
    background:#479779;
    padding: 10px;
    border-radius: 50%;
    color: #ffff;
}
.titleHome{
    color: #0db26b;
    font-weight: bold;
    text-decoration: none;
}
.home-title{
  color: #a74e85;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}
.home-title:hover{
  color: #a74e85;
}

.textHistory{
  text-align: justify;
  color: #474747;
  z-index:2;
}
.title-about{
  color: #0db26b;
  text-transform: uppercase;
  font-weight: bold;
}
.bgLcFarma{
  width: 356px;
  height: 248px;
}
.imgColaborator{
  width: 150px;
  height: 75px;
}
.imgColaborator2{
  width: 150px;
  height: 75px;
}
.imgColaborator3{
  width: 180px;
  height: 50px;

}
.multiLineTextLeft{
  white-space: pre-line;
  text-align: left;
}
.multiLineText{
  white-space: pre-line;
}

.imageCert{
  width: 200px;
  height: 300px;
}
.modalImage{
  width:500px;
  height: 600px;
}
@media screen and (max-width: 800px) {
  .modalImage{
    width: 300px;
    height: 400px
  }
}
.box-location {
  background: #0db26b;
  padding: 15px;
  color: #ffff;
  font-weight: bold;
}
.form-location {
  background: #b2d5c6 !important;
  border-radius: 0 !important;
  border-bottom: 3px solid #097747 !important;
}
.form-location::-webkit-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}
.form-location:-ms-input-placeholder {
  color: #000 !important;
  opacity: 1 !important;
}
.form-location::placeholder {
  color: #000 !important;
  opacity: 1 !important;
}
.form-location:focus {
  background: #d7f6e9 !important;
  border: 1px solid transparent !important;
  border-bottom: 3px solid #c37bdd !important;
  box-shadow: none !important;
}
.btn-formSend{
  background: #22ce88 !important;
  color: #fff !important;
  font-size: 18px !important;
  font-weight: bolder !important;
  border-radius: 20px !important;
  width: 100%
}
.btn-formSend:hover{
  background: #a31a91 !important;
}
.btn-formSend:focus{
  box-shadow: none !important;
}
.bgLogin {
  background-image: url(/static/media/farma3.70c9b785.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login{
  background-color: rgb(0, 130, 75, 0.8);
  padding: 25px !important;
  border-radius: 40px;
  color: #ffff
}
.loginInput{
  background-color: rgb(37, 107, 80, 0.6) !important;
  color: #ffff !important;
  border: transparent !important;
}
.btnSignIn{
  background-color: rgb(31, 76, 107) !important;
  color: #ffff !important;
}
.btnSignIn:hover{
  background-color: rgb(31, 76, 90) !important;
}
.btnClear{
  background-color: rgb(56, 38, 85, 0.8) !important;
  color: #ffff !important;
}
.btnClear:hover{
  background-color: rgb(80, 75, 111, 0.9) !important;
}

.imageAdmin{
  background-image: url(/static/media/drugs.c4d9889c.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
}
.presentAdmin{
  background-color: rgb(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 40px;
}

.imageProduct {
  width: 271px;
  height: 162px;
}

.imageProductModal{
  max-width: 300px !important;
  height: 170px !important;
}

.imageProductModal2{
  max-width: 100% !important;
  height: 220px !important;
}

.innerCarousel{
  width: 300px !important;
  height: 200px !important;
  background: #ffff !important;
}

.iconProduct {
  font-size: 45px;
  background: #8e4797;
  padding: 15px;
  border-radius: 50%;
  color: #ffff;
}

.text-left {
  text-align: left;
}
.containerSlider{
  background: #ffff!important;
}
.slideImg{
  max-height: 200px;
}

.logoFooter{
  width: 50px;
}
.footer{
  background:#137251;
  color:#fff;
}
.footer-header{
  background:#097747;
  color:rgb(255, 255, 255);
}
.footer-admin{
  background:#137251;
  color:#fff;
}

.slidebar {
  background-color: #377968;
}
.slideBtn {
  background-color: rgb(38, 145, 109) !important;
  text-align: center;
  padding: 0;
}
.btn-slider {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.slideBtn:hover {
  background-color: rgb(55, 173, 134) !important;
}
.slideBtn a {
  color: #ffff;
  text-decoration: none;
}
.slideBtn a:hover {
  color: #ffff;
  text-decoration: none;
}
.slide {
  background-color: rgb(48, 90, 65) !important;
}
.imageFooter {
  width: 50px;
}

* {
  font-family: "Zen Kaku Gothic Antique", sans-serif;
}
.parraf {
  color: #656565;
}
.btnPurple {
  background: #9b3675 !important;
  border-radius: 50px !important;
  color: #ffff !important;
}
.btnPurple:hover {
  background: #327e51 !important;
}
.btn-goUp {
  background: #1b5b3f !important;
  border-radius: 20px !important;
  color: #ffff !important;
  margin-right: 15px;
  width: 150px !important;
}
.btn-goUp:hover {
  background: rgb(44, 102, 77) !important;
}
a {
  text-decoration: none !important;
}

