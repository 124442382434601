.imageAdmin{
  background-image: url('../img/drugs.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
}
.presentAdmin{
  background-color: rgb(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 40px;
}
