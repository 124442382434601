.imageProduct {
  width: 271px;
  height: 162px;
}

.imageProductModal{
  max-width: 300px !important;
  height: 170px !important;
}

.imageProductModal2{
  max-width: 100% !important;
  height: 220px !important;
}

.innerCarousel{
  width: 300px !important;
  height: 200px !important;
  background: #ffff !important;
}

.iconProduct {
  font-size: 45px;
  background: #8e4797;
  padding: 15px;
  border-radius: 50%;
  color: #ffff;
}

.text-left {
  text-align: left;
}
.containerSlider{
  background: #ffff!important;
}
.slideImg{
  max-height: 200px;
}
