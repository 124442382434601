.bgLogin {
  background-image: url("../img/farma3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login{
  background-color: rgb(0, 130, 75, 0.8);
  padding: 25px !important;
  border-radius: 40px;
  color: #ffff
}
.loginInput{
  background-color: rgb(37, 107, 80, 0.6) !important;
  color: #ffff !important;
  border: transparent !important;
}
.btnSignIn{
  background-color: rgb(31, 76, 107) !important;
  color: #ffff !important;
}
.btnSignIn:hover{
  background-color: rgb(31, 76, 90) !important;
}
.btnClear{
  background-color: rgb(56, 38, 85, 0.8) !important;
  color: #ffff !important;
}
.btnClear:hover{
  background-color: rgb(80, 75, 111, 0.9) !important;
}
